import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import { Link } from "gatsby";

import imgRounded from "../../assets/image/png/l2-content1-oval.png";
import imgHalf from "../../assets/image/jpeg/content1.jpg";
import Img from "gatsby-image"
import { htmlSerializer } from '../../utils/htmlSerializer'
import { RichText } from 'prismic-reactjs';

const CenterCol = styled(Col)`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

`

const StyledImg = styled(Img)`
@media (max-width: 400px) {
  width: 110% !important;
}
width: 100% !important;

height: 300px;


`


const Content1 = ({ document }) => (
  <>
    <div className="overflow-hidden">
      {document.map((item, index) => (
        <Container>
          <Row>

            <CenterCol md={6} lg={6} className="position-relative mb-5 mb-lg-0">
              <Title>{item.title1.text}</Title>

              <StyledImg fluid={item.image.localFile.childImageSharp.fluid} alt="" />
            </CenterCol>
            <CenterCol
              md={6}
              lg={6}
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <Section py={[null, null, null, "50px"]}>
                <div className="omga-02__content-text section-title order-lg-1 m-2 m-md-5">

                  <Text>

                  <RichText render={item.copy.raw} htmlSerializer={htmlSerializer} />


                  </Text>
                  <Link to="/contact">
                    <Button mt={4} mb={4}>{item.cta_text.text}</Button>

                  </Link>
                </div>
              </Section>
            </CenterCol>
          </Row>
        </Container>
      ))}

    </div>
  </>
);

export default Content1;
