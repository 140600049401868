import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import {Link} from "gatsby";

import GlobalContext from "../../context/GlobalContext";

import { device } from "../../utils";
import svgHeroShape from "../../assets/image/svg/hero-shape-svg.svg";
import imgL1HeroPhone from "../../assets/image/svg/index_hero.svg";
import imgL1Brand1 from "../../assets/image/png/brand-logo-l5-1.png";
import imgL1Brand2 from "../../assets/image/png/brand-logo-l5-2.png";
import imgL1Brand3 from "../../assets/image/png/brand-logo-l5-3.png";
import imgL1Brand4 from "../../assets/image/png/brand-logo-l5-4.png";
import imgL1Brand5 from "../../assets/image/png/brand-logo-l5-6.png";
import { RichText } from 'prismic-reactjs';

import { htmlSerializer } from '../../utils/htmlSerializer'

import Img from "gatsby-image"

const ImgRight = styled.img`
  max-width: 60%;
  @media ${device.sm} {
    max-width: 60%;
  }
  @media ${device.lg} {
    max-width: 120%;
  }
`;

const TitleContainer = styled(Box)`
  position: relative;

  @media ${device.sm} {
    padding-top: 2rem;
  }


  &:after {
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 32%;
    background: ${({ theme }) => theme.colors.border};
    margin-top: 0.5px;
    display: none;

    @media ${device.md} {
      width: 40%;
      display: block;
    }
    @media ${device.lg} {
      width: 52%;
    }
    @media ${device.xl} {
      width: 60%;
    }
  }
`;

const Brand = styled(Box)`
  opacity: 0.7;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
  }

position: relative;
 

  img{
    width: 130px;
    height: 75px;    }

    img{
      object-fit: contain;

    }

`;

const StyledSection = styled(Section)`

min-height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

`

const Hero = ({document}) => {
  const gContext = useContext(GlobalContext);

  const openVideoModal = (e) => {
    e.preventDefault();
    gContext.toggleVideoModal();
  };

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection className="position-relative mt-4 mt-md-0" css={{paddingBottom: "0px;", minHeight: "100vh"}}>

        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="5" md="8" sm="9" className="order-lg-2">
              <div className="text-center text-lg-right position-relative">
                <div
                  className="img-main"
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <ImgRight src={imgL1HeroPhone} alt="" />
                </div>
              </div>
            </Col>
            <Col lg="7" className="order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title variant="card">{document.data.hero_subline.text}</Title>
                  <Title variant="hero">{document.data.hero_title.text}</Title>
                  <Row>

                    <Col sm={12} className='order-lg-1'>
                    <div className="d-flex flex-column align-items-start">
                      <Link to="/contact">
                      <Button mb={3}>{document.data.cta_button_text.text}</Button>

                      </Link>
                  </div>

                    </Col>
                    <Col sm={12} className='d-none d-md-flex'>
                    <Text mb={4}>

                      {document.data.hero_copy.text}

                  </Text>

                    </Col>
                  </Row>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
        <Box pb={"30px"} className='w-100'>
      <Container>
        <TitleContainer>Industry Certifications</TitleContainer>
      </Container>
      <Container>
        <Box
          className="d-flex justify-content-center justify-content-lg-between
         align-items-center flex-wrap"
          mx="-32px"
        > 
        {document.data.industry_certifications.map((item, index) => (
          <Brand className="" py={3} mx={4}>
          <img src={item.certification_image.url} alt=""/>
        </Brand>
      ))}

        </Box>
      </Container>
    </Box>

      </StyledSection>
    </>
  );
};

export default Hero;
