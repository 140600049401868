import React from "react";
import Hero from "../sections/landing1/Hero";
import Clients from "../sections/landing1/Clients";
import CTA from "../sections/landing1/CTA";
import PageWrapper from "../components/PageWrapper";
import Content1 from "../sections/landing1/Content1";
import Content3 from "../sections/landing1/Content3";
import Feature2 from "../sections/landing1/Feature2";
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicHomepage.nodes[0]
  const ctaDocument = data.allPrismicCta.nodes[0]

  return (
    <>
      <PageWrapper footerLight>
        <Hero document={document} />
        <Content1 document={document.data.first_copy_blocks}/>
        <Feature2 document={document.data.services}/>
        <Content3 document={document.data.second_copy_blocks} />
        <CTA document={ctaDocument.data}/>
      </PageWrapper>
    </>
  );
};
export default IndexPage;

export const query = graphql`
query HomePage {
  allPrismicHomepage {
    nodes {
      data {
        cta_button_text {
          text
        }
        description {
          text
        }
        first_copy_blocks {
          title1 {
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          cta_text {
            text
          }
          copy {
            raw
          }
        }
        hero_copy {
          text
        }
        hero_image {
          localFile {
            childImageSharp {
              fluid {
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        hero_subline {
          text
        }
        hero_title {
          text
        }
        industry_certifications {
          certification_image {
            url
          }
        }
        our_services
        second_copy_blocks {
          title1 {
            text
          }
          copy {
            raw
          }
          cta_text {
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
        title {
          text
        }
        services {
          service_name {
            text
          }
        }
      }
    }
  }
  allPrismicCta {
    nodes {
      data {
        cta_title {
          text
        }
        cta_button_text {
          text
        }
      }
    }
  }
}

`
